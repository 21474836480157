<template>
    <div id="Meet">
        <div class="meetingMaster">
            <div class="info">
                <h2 class="text">{{ contactLabels.MainLabels.title[currentLanguage] }}</h2>
                <p class="subtext">{{ contactLabels.MainLabels.description[currentLanguage] }}</p>
            </div>
            <div class="form">
              <form id="form" @submit.prevent="sendEmail">
                <div class="form-row">
                  <div class="input-data">
                    <input type="text" id="MtName" required>
                    <div class="underline"></div>
                    <label>{{ contactLabels.MainLabels.name[currentLanguage] }}</label>
                  </div>
                  <div class="input-data">
                    <input type="email" id="MtEmail" required>
                    <div class="underline"></div>
                    <label>{{ contactLabels.MainLabels.email[currentLanguage] }}</label>
                  </div>
                  <div class="input-data">
                    <input type="text" id="MtPhone" required>
                    <div class="underline"></div>
                    <label>{{ contactLabels.MainLabels.phone[currentLanguage] }}</label>
                  </div>
                </div>

                <div class="form-row">
                  <div class="input-data textarea">
                    <textarea id="MtIssue" rows="8" cols="80" required></textarea>
                    <br />
                    <div class="underline"></div>
                    <label>{{ contactLabels.MainLabels.issue[currentLanguage] }}</label>
                  </div>
                </div>
                
                <div class="form-row">
                  <div class="input-data">
                    <div class="dropdown">
                      <select id="MtDate" required>
                        <option v-for="date in selectableDates" :key="date" :value="date">{{ formatDate(date) }}</option>
                      </select>
                    </div>
                  </div>

                  <div class="input-data">
                    <div class="dropdown">
                      <select id="MtTime" required>
                        <option value="10:00">10:00 AM</option>
                        <option value="11:00">11:00 AM</option>
                        <option value="14:00">2:00 PM</option>
                        <option value="15:00">3:00 PM</option>
                        <option value="16:00">4:00 PM</option>
                      </select>
                    </div>
                  </div>
                </div> 

                <div class="form-row">
                  <div class="form-row submit-btn">
                    <div class="input-data">
                      <div class="inner"></div>
                      <input type="submit" value="Submit">
                    </div>
                  </div>
                </div>
              </form>

            </div>
        </div>

        <div v-if="success" class="popsuccess">
          <i style="color: green;" class="fa-solid fa-circle-check"></i>
          <h3 style="color: green;">{{currentLanguage==='en' ? "Sent" : "Exito"}}</h3>
        </div>

    </div>
</template>

<script>
import contactLabels from "../assets/textfiles/meet.js";
import emailjs from '@emailjs/browser';



export default {
  props: ['currentLanguage'],
  data() {
    return {
      success: false,
      contactLabels: contactLabels,
      selectedDate: '', // Initialize as empty string
      minDate: '', // Calculate minimum selectable date
      maxDate: '', // Calculate maximum selectable date
      daysOfWeek: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
      serviceId: 'service_sortolegal',    
      templateId: 'template_Sortomeet'
    }
  },
  computed: {
    selectableDates() {
    const dates = [];
    let currentDate = new Date();
    const minDate = new Date(currentDate.getTime() + 7 * 24 * 60 * 60 * 1000); // One week in advance
    const maxDate = new Date(currentDate.getTime() + 14 * 24 * 60 * 60 * 1000); // Two weeks in advance
    // Iterate through each day within the range and add weekdays to the selectableDates array
    while (currentDate <= maxDate) {
      // Check if the current day is a weekday (Monday to Friday)
      if (currentDate.getDay() !== 0 && currentDate.getDay() !== 6) {
        if (currentDate >= minDate && currentDate <= maxDate) {
          dates.push(new Date(currentDate)); // Clone the date object to avoid side effects
        }
      }
      currentDate = new Date(currentDate.getTime() + 24 * 60 * 60 * 1000); // Move to the next day
    }
    return dates;
  }
  },
  methods: {
    formatDate(date) {
      const dayOfWeek = this.daysOfWeek[date.getDay()];
      const formattedDate = date.toISOString().split('T')[0]; // Convert date to YYYY-MM-DD format
      return `${dayOfWeek}, ${formattedDate}`;
    },
    sendEmail() {      
      //Prepare the email parameters
      const params = {
        Mname : document.getElementById("MtName").value,
        Memail : document.getElementById("MtEmail").value,
        Mphone : document.getElementById("MtPhone").value,
        Missue : document.getElementById("MtIssue").value,
        Mdate : document.getElementById("MtDate").value,
        Mtime : document.getElementById("MtTime").value,
      };
      const form = document.getElementById("form");
      // Send the email using EmailJS
      emailjs
        .send(this.serviceId, this.templateId, params, {
          publicKey: 'G6ILzncS11LzqrPvz',
        })
        .then(
          () => {
            console.log('SUCCESS!');
            form.reset();
            this.success = true;
            // Start a timer to hide the popup after 3 seconds
            setTimeout(() => {
              this.success = false;
            }, 3000);
          },
          (error) => {
            console.log('FAILED...', error.text);
          },
        );
    }
  }
}
</script>

<style>
  

 #Meet{
        background-color: #002741;
        width: 100%;
        height: 100%;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .popsuccess{
      position: fixed;
      display: flex;
      z-index: 100;
      
      bottom: 0%;
      top: 50%;

      background-color: white;
      box-shadow: 0px 0px 10px #00000085;
      border-radius: 20px;
      width: 150px;
      height: 150px;

      justify-content: center;
      align-items: center;
      flex-direction: column;
    }

    .meetingMaster{
        justify-content: center;
        max-width: 800px;
        height: 80%;
        background: #002741;
        width: 800px;
        padding: 25px 40px 10px 40px;
        display: flex;
        flex-direction: column;
    }
    .meetingMaster .text{
        text-align: center;
        font-size: 41px;
        font-weight: 600;
        font-family: 'Poppins', sans-serif;
    }

    .meetingMaster .subtext{
        text-align: center;
        font-size: 20px;
        font-weight: 400;
        font-style: italic;
        color: #ffffff96;
        margin: 0%;
        padding-bottom: 5%;
    }

.meetingMaster form{
  padding: 10px;
}
.meetingMaster form .form-row{
  display: flex;
  margin: 0px 0;
  padding-bottom: 2%;
  width: 100%;
}
form .form-row .input-data{
  width: 100%;
  height: 40px;
  margin: 0 20px;
  position: relative;
}
form .form-row .textarea{
  height: 70px;
}
.input-data input,
.textarea textarea{
  display: block;
  width: 100%;
  height: 100%;
  border: none;
  font-size: 17px;
  border-bottom: 2px solid rgba(0,0,0, 0.12);
}
.input-data input:focus ~ label, .textarea textarea:focus ~ label,
.input-data input:valid ~ label, .textarea textarea:valid ~ label{
  transform: translateY(-10px);
  font-size: 10px;
  color: #3498db;
}
.textarea textarea{
  resize: none;
  padding-top: 10px;
  width: 100%;
}
.input-data label{
  position: absolute;
  pointer-events: none;
  left: 5px;
  bottom: 3%;
  font-size: 16px;
  transition: all 0.3s ease;
  color: #00274179;
}
.textarea label{
  width: 100%;
  bottom: 40px;
  text-align: left;
}
.input-data .underline{
  position: absolute;
  bottom: -5px;
  left: -50%;
  height: 2px;
  width: 100%;
}
.input-data .underline:before{
  position: absolute;
  content: "";
  height: 2px;
  width: 100%;
  background: #3498db;
  transform: scaleX(0);
  transform-origin: center;
  transition: transform 0.3s ease;
}
.input-data input:focus ~ .underline:before,
.input-data input:valid ~ .underline:before,
.textarea textarea:focus ~ .underline:before,
.textarea textarea:valid ~ .underline:before{
  transform: scale(1);
}
.submit-btn .input-data{
  overflow: hidden;
  height: 45px!important;
  width: 100%!important;
}
.submit-btn .input-data .inner{
  height: 100%;
  width: 300%;
  position: absolute;
  left: -100%;
  background: -webkit-linear-gradient(right, #56d8e4, #9f01ea, #56d8e4, #9f01ea);
  transition: all 0.4s;
}
.submit-btn .input-data:hover .inner{
  left: 0;
}
.submit-btn .input-data input{
  background: none;
  border: none;
  color: #fff;
  font-size: 17px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;
  position: relative;
  z-index: 2;
}

.input-data .dropdown{
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: flex-start;
    align-items: stretch;
}

@media (max-width: 700px) {
  .meetingMaster{
        width: 70%;
        height: 100%;
        background: #002741;
        padding: 25px 40px 10px 40px;
        display: flex;
        flex-direction: column;
    }


  .meetingMaster .text{
    font-size: 30px;
  }

  .meetingMaster .subtext{
    font-size: 15px;
  }
  .meetingMaster form{
    padding: 10px 0 0 0;
  }
  .meetingMaster form .form-row{
    display: block;
    padding-bottom: 0%;
  }
  form .form-row .input-data{
    padding: 0%;
  }
  .submit-btn{
    width: 100%;
  }
}

</style>