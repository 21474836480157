export default [
    { //Vet Law
      title: {
        en: 'Animal Protection and Welfare',
        es: 'Protección y Bienestar Animal'
      },
      image: 'veterinary.png',
      description: {
        en: 'Legal representation for cases involving animal welfare and protection.',
        es: 'Representación legal en casos relacionados con el bienestar y la protección animal.'
      },
      services: [
        {
          id: 1,
          name: {
            es: 'Representación en audiencias de infracciones',
            en: 'Representation in infringement hearings'
          }
        },
        {
          id: 2,
          name: {
            en: 'Representation in animal cruelty cases',
            es: 'Representación en delitos contra el maltrato animal'
          }
        },
        {
          id: 3,
          name: {
            en: 'Representation in animal abandonment cases',
            es: 'Representación en delito contra el abandono animal'
          }
        }
      ]      
    },
    { //Civil Law
      title: {
        es: 'Derecho Civil',
        en: 'Civil Law'
      },
      image: 'civil.webp',
      description: {
          en: 'Offering expert advice and representation in civil matters, ensuring your legal rights are upheld in all civil proceedings.',
          es: 'Ofreciendo asesoramiento y representación legal en asuntos civiles, asegurando que sus derechos legales sean protegidos en todos los procedimientos civiles.'
      },
      services: [
        {
          id: 1,
          name: {
            en: 'Foreclosure Proceedings',
            es: 'Ejecuciones Hipotecarias'
          }
        },
        {
          id: 2,
          name: {
            en: 'Breach of Contracts',
            es: 'Incumplimiento de Contratos'
          }
        },
        {
          id: 3,
          name: {
            en: 'Enforcement of Judicial Titles',
            es: 'Ejecución de Títulos Judiciales'
          }
        },
        {
          id: 4,
          name: {
            en: 'Enforcement of Extrajudicial Titles (Promissory Notes, Bills of Exchange, Others)',
            es: 'Ejecución de Títulos Extrajudiciales (Pagarés, Letras de Cambio, Otros)'
          }
        },
        {
          id: 5,
          name: {
            en: 'Unfair Competition',
            es: 'Competencia Desleal'
          }
        },
        {
          id: 6,
          name: {
            en: 'Industrial Property',
            es: 'Propiedad Industrial'
          }
        },
        {
          id: 7,
          name: {
            en: 'Intellectual Property',
            es: 'Propiedad Intelectual'
          }
        },
        {
          id: 8,
          name: {
            en: 'Expiration of Lease and Challenge of Deposits for Causes Established in the Tenancy Law',
            es: 'Expiración del Arrendamiento e Impugnación de Depósitos por las Causas Establecidas en la Ley de Inquilinato'
          }
        },
        {
          id: 9,
          name: {
            en: 'Rectification of Inaccurate and Damaging Facts or Information',
            es: 'Rectificación de Hechos o Informaciones Inexactas y Perjudiciales'
          }
        },
        {
          id: 12,
          name: {
            en: 'Horizontal Property',
            es: 'Propiedad Horizontal'
          }
        },
        {
          id: 13,
          name: {
            en: 'Acquisitive Prescription, Boundary Demarcation and Mooring',
            es: 'Prescripción Adquisitiva, Deslinde y Amojonamiento'
          }
        },
        {
          id: 14,
          name: {
            en: 'Claims Arising from Traffic Accidents',
            es: 'Pretensiones Derivadas de Accidentes de Tránsito'
          }
        }
      ]
    },
    { //Commercial Law
      title: {
        en: 'Commercial Law',
        es: 'Derecho Mercantil'
      },
      image: 'comercial.jpeg',
      description: {
        en: 'Providing comprehensive legal support for your business operations, from contract drafting to dispute resolution.',
        es: 'Proporcionando apoyo legal integral para las operaciones comerciales de su empresa, desde la redacción de contratos hasta la resolución de disputas.'
      },
      services: [
        {
          id: 1,
          name: {
            en: 'Sales and Purchases',
            es: 'Compraventas'
          }
        },
        {
          id: 3,
          name: {
            en: 'Contracts of Intellectual and Industrial Property: Trademarks, Patents, and Utility Models',
            es: 'Contratos de Propiedad Intelectual e Industrial: Marcas, Patentes y Modelos de Utilidad'
          }
        },
        {
          id: 4,
          name: {
            en: 'Commercial Loan Contracts',
            es: 'Contratos de Préstamo Mercantil'
          }
        },
        {
          id: 5,
          name: {
            en: 'Contracts for Works Execution and Provision of Services',
            es: 'Contratos de Ejecución de Obras y Prestación de Servicios'
          }
        },
        {
          id: 6,
          name: {
            en: 'Registration and Procedures before the Commercial Registry',
            es: 'Inscripción y Gestiones ante el Registro Mercantil'
          }
        },
        {
          id: 7,
          name: {
            en: 'Drafting of Representation Powers',
            es: 'Redacción de Poderes de Representación'
          }
        },
        {
          id: 8,
          name: {
            en: 'Public Deed of Incorporation of Company and Bylaws if it is a Corporation',
            es: 'Escritura Pública de Constitución de Sociedad y Estatutos si es S.A.'
          }
        },
        {
          id: 11,
          name: {
            en: 'Incorporation of the Company in the Chamber of Commerce',
            es: 'Inscripción de la Sociedad en la Cámara de Comercio'
          }
        },
        {
          id: 12,
          name: {
            en: 'Obtaining the National Tax Registry (RTN) at the Tax Administration Service (SAR)',
            es: 'Obtención del Registro Tributario Nacional (RTN) en el Servicio de Administración de Rentas (SAR)'
          }
        },
        {
          id: 13,
          name: {
            en: 'Operation Permit',
            es: 'Permiso de Operación'
          }
        },
        {
          id: 14,
          name: {
            en: 'Obtaining Permits, Licenses, Registrations, or Authorizations required according to the nature of your business',
            es: 'Obtención de Permisos, Licencias, Registros o Autorizaciones que según el rubro de su empresa necesite'
          }
        }
      ]
    },
    { //Intellectual Property
      title: {
        en: 'Industrial and Intellectual Propery',
        es: 'Propiedad Industrial e Intelectual'
      },
      image: 'industrial.webp',
      description: {
        en: 'Safeguarding your intellectual property rights, from patent registration to trademark protection and copyright enforcement.',
        es: 'Protegiendo sus derechos de propiedad intelectual, desde el registro de patentes hasta la protección de marcas comerciales y la aplicación del derecho de autor.'
      },
      services: [
        {
          id: 1,
          name: {
            en: 'Advisory on Industrial and Intellectual Property Rights',
            es: 'Asesoramiento en relación con derechos de la Propiedad Industrial e Intelectual'
          }
        },
        {
          id: 2,
          name: {
            en: 'Registration and Licensing Grants and other contractual arrangements (trademarks, patents, industrial designs)',
            es: 'Registro y otorgamiento de licencias y demás arreglos contractuales (marcas, patentes, diseños industriales)'
          }
        },
        {
          id: 4,
          name: {
            en: 'Administrative Procedures before the Trademark Office',
            es: 'Procedimientos Administrativos ante la Oficina de Marcas'
          }
        },
        {
          id: 6,
          name: {
            en: 'Litigation in matters of trademarks, patents, designs, and domain names',
            es: 'Litigios en materia de marcas, patentes, diseños y nombres de dominio'
          }
        },
        {
          id: 7,
          name: {
            en: 'Actions against piracy and rights infringement',
            es: 'Acciones contra la piratería y la usurpación de derechos'
          }
        },
        {
          id: 8,
          name: {
            en: 'Procedures for infringement of Intellectual Property rights',
            es: 'Procedimientos por infracción de derechos de Propiedad Intelectual'
          }
        },
        {
          id: 9,
          name: {
            en: 'Defense actions for copyright, author rights, and related rights',
            es: 'Acciones de defensa de copyright, derechos de autor y derechos conexos'
          }
        },
        {
          id: 11,
          name: {
            en: 'Negotiation and drafting of assignment and license contracts for exploitation rights',
            es: 'Negociación y elaboración de contratos de cesión y licencia de uso de derechos de explotación'
          }
        },
        {
          id: 12,
          name: {
            en: 'Compensation for damages and losses',
            es: 'Indemnización por daños y perjuicios'
          }
        }
      ]
    },
    { //Immigration Law
      title: {
        en: 'Immigration Law',
        es: 'Derecho Migratorio'
      },
      image: 'imigracion.jpg',
      description: {
        en: 'Assisting with immigration matters, from visa applications to residency permits, ensuring a smooth transition for foreigners.',
        es: 'Asistiendo en asuntos migratorios, desde solicitudes de visa hasta permisos de residencia, asegurando una transición fluida para los extranjeros.'
      },
      services: [
        {
          id: 1,
          name: {
            en: 'Citizenship Management',
            es: 'Gestión de Ciudadanía'
          }
        },
        {
          id: 2,
          name: {
            en: 'Residence Management',
            es: 'Gestión de Residencia'
          }
        },
        {
          id: 3,
          name: {
            en: 'Management of all types of stay permits',
            es: 'Gestión de Todo Tipo de Permisos de Permanencia'
          }
        },
        {
          id: 4,
          name: {
            en: 'Nationality',
            es: 'Nacionalidad'
          }
        },
        {
          id: 5,
          name: {
            en: 'Nationality Processing by Residence',
            es: 'Tramitación de Nacionalidad por Residencia'
          }
        },
        {
          id: 6,
          name: {
            en: 'Nationality Processing by Marriage to a Honduran Citizen',
            es: 'Tramitación de Nacionalidad por Matrimonio con Hondureño(a)'
          }
        },
        {
          id: 7,
          name: {
            en: 'Registration of Marriages Celebrated Outside of Honduras',
            es: 'Inscripción de Matrimonios Celebrados Fuera de Honduras'
          }
        },
        {
          id: 9,
          name: {
            en: 'Residence for having a marital bond or common-law union',
            es: 'Residencia por tener vínculo matrimonial o unión de hecho'
          }
        },
        {
          id: 10,
          name: {
            en: 'Retiree Residence',
            es: 'Residencia de Pensionado'
          }
        },
        {
          id: 11,
          name: {
            en: 'Rentier Residence',
            es: 'Residencia de Rentista'
          }
        },
        {
          id: 12,
          name: {
            en: 'Immigrant Residence',
            es: 'Residencia por Inmigrado'
          }
        },
        {
          id: 13,
          name: {
            en: 'Residence by family ties',
            es: 'Residencia por vínculo familiar'
          }
        },
        {
          id: 14,
          name: {
            en: 'Stay Permits',
            es: 'Permisos de Permanencia'
          }
        },
        {
          id: 15,
          name: {
            en: 'Students',
            es: 'Estudiantes'
          }
        },
        {
          id: 16,
          name: {
            en: 'Foreigners in Social Services',
            es: 'Extranjeros en Servicio Social'
          }
        },
        {
          id: 17,
          name: {
            en: 'Religious Workers',
            es: 'Religiosos'
          }
        },
        {
          id: 20,
          name: {
            en: 'Foreigners Hired as Temporary Employees',
            es: 'Extranjeros Contratados como Empleados Temporales'
          }
        }
      ]
    },
    {//family law
      title: {
        en: 'Family and Succession | Inheritance Law',
        es: 'Derecho de Familia y Sucesorio | Herencias'
      },
      image: 'family.jpg',
      description: {
        en: 'Providing legal assistance in family law and inheritance matters, from divorce proceedings to estate planning and probate.',
        es: 'Proporcionando asistencia legal en asuntos de derecho de familia y sucesiones, desde procesos de divorcio hasta planificación patrimonial y sucesiones.'
      },
      services: [
        {
          id: 1,
          name: {
            en: 'Advisory on De facto Separations and Divorces by Mutual Agreement or Contested',
            es: 'Asesoramiento en Separaciones de hecho y Divorcios de Mutuo Acuerdo o Contenciosos'
          }
        },
        {
          id: 2,
          name: {
            en: 'Advisory for Civil Marriage',
            es: 'Asesoramiento para Matrimonio Civil'
          }
        },
        {
          id: 3,
          name: {
            en: 'Guardianship and Custody of Minors',
            es: 'Guarda y Custodia de Menores'
          }
        },
        {
          id: 4,
          name: {
            en: 'Visitation Rights',
            es: 'Régimen de Visitas'
          }
        },
        {
          id: 5,
          name: {
            en: 'Paternity and Filiation Claims or Challenges',
            es: 'Reclamación o Impugnación de Paternidad'
          }
        },
        {
          id: 6,
          name: {
            en: 'Declaration of Incapacity',
            es: 'Declaración de Incapacidad'
          }
        },
        {
          id: 7,
          name: {
            en: 'Child Support Claims',
            es: 'Reclamación de Pensión Alimenticia'
          }
        },
        {
          id: 8,
          name: {
            en: 'Parental Authority Proceedings',
            es: 'Procedimientos de Patria Potestad'
          }
        },
        {
          id: 12,
          name: {
            en: 'Legal Advice on Succession Law and Testamentary Succession',
            es: 'Asesoramiento Legal en Relación al Derecho Sucesorio y Sucesión Testamentaria'
          }
        },
        {
          id: 14,
          name: {
            en: 'Inheritance Claims',
            es: 'Reclamaciones de Herencias'
          }
        }
      ]
    },
    { //registry law
      title: {
        en: 'Registry Law',
        es: 'Derecho Registral'
      },
      image: 'registral.jpg',
      description: {
        en: 'Providing legal assistance related to registry matters, from real estate transactions to property registration and resolution of contracts.',
        es: 'Proporcionando asistencia legal relacionada con asuntos registrales, desde transacciones inmobiliarias hasta registro de propiedades y resolución de contratos.'
      },
      services: [
        {
          id: 1,
          name: {
            en: 'Advisory related to the buying and selling of real estate',
            es: 'Asesoramiento relacionado con la compra y venta de inmuebles'
          }
        },
        {
          id: 2,
          name: {
            en: 'Drafting of lease agreements',
            es: 'Redacción de contratos de arrendamiento'
          }
        },
        {
          id: 3,
          name: {
            en: 'Registry inscription',
            es: 'Inscripción registral'
          }
        },
        {
          id: 7,
          name: {
            en: 'Rights registration',
            es: 'Inscripción de derechos'
          }
        },
        {
          id: 8,
          name: {
            en: 'Property registration',
            es: 'Registro de la Propiedad'
          }
        },
        {
          id: 9,
          name: {
            en: 'Preventive annotations of seizure',
            es: 'Anotaciones preventivas de embargo'
          }
        },
        {
          id: 10,
          name: {
            en: 'Horizontal Property: constitution and advisory',
            es: 'Propiedad Horizontal: constitución y asesoramiento'
          }
        },
        {
          id: 11,
          name: {
            en: 'Real Estate Registry',
            es: 'Registro Inmueble'
          }
        },
        {
          id: 12,
          name: {
            en: 'Certification of Property Rights',
            es: 'Certificación de Derechos de Propiedad'
          }
        },
        {
          id: 13,
          name: {
            en: 'Clearance Certificates',
            es: 'Constancias de Libertad de Gravamen'
          }
        },
        {
          id: 14,
          name: {
            en: 'Information on property rights and successive tracts',
            es: 'Información sobre derechos de propiedad y tractos sucesivos'
          }
        },
        {
          id: 15,
          name: {
            en: 'Public Instruments Registry',
            es: 'Registro de Instrumentos Públicos'
          }
        },
        {
          id: 16,
          name: {
            en: 'Registrations of: Individualization or Partition, Improvement Registration, Remedied Registration, Cancellation Deeds, Donation Deeds, Powers of Attorney Registration, Sale of Rights, Full Ownership Deeds, Constitution Deeds, Use and Habitation, Usufruct, Cancellation of Liens, Mortgage Cancellation',
            es: 'Inscripciones de: Individualización o Partición, Inscripción de Mejoras, Inscripción Remedida, Actas de Cancelación, Escritura de Donación, Inscripción de Poderes, Venta de Derechos, Escrituras de Dominio Pleno, Escritura de Constitución , Uso y Habitación, Usufructo, Cancelación de Gravamen, Cancelación de Hipoteca'
          }
        }
      ]
    },
    { //labor law
      title: {
        en: 'Labor Law',
        es: 'Derecho Laboral'
      },
      image: 'laboral.jpg',
      description: {
        en: 'Providing legal assistance in labor matters, from advisory services to representation in labor-related processes.',
        es: 'Proporcionando asistencia legal en asuntos laborales, desde servicios de asesoramiento hasta representación en procesos laborales.'
      },
      services: [
        {
          id: 1,
          name: {
            en: 'Advisory for companies, in labor matters',
            es: 'Asesoramiento para empresas en materia laboral'
          }
        },
        {
          id: 2,
          name: {
            en: 'Advisory in processes before the Labor Authority, Courts, and Tribunals',
            es: 'Asesoramiento en procesos ante la Autoridad Laboral, Juzgados y Tribunales'
          }
        },
        {
          id: 3,
          name: {
            en: 'Advisory and defense before Labor and Social Security Bodies',
            es: 'Asesoramiento y defensa ante los Organismos del Trabajo y Seguridad Social'
          }
        },
        {
          id: 5,
          name: {
            en: 'Personnel management and administration',
            es: 'Gestión y administración de personal'
          }
        },
        {
          id: 6,
          name: {
            en: 'Preparation of Work Regulations',
            es: 'Elaboración de Reglamentos de Trabajo'
          }
        },
        {
          id: 9,
          name: {
            en: 'Preparation of Labor contracts',
            es: 'Elaboracion Contratos Laborales'
          }
        },
        {
          id: 10,
          name: {
            en: 'Advisory in Dismissals',
            es: 'Asesoramiento en Despidos'
          }
        },
        {
          id: 11,
          name: {
            en: 'Advisory and appearance in conciliation hearings',
            es: 'Asesoramiento y comparecencia en audiencias de conciliación'
          }
        },
        {
          id: 13,
          name: {
            en: 'Disciplinary proceedings',
            es: 'Procedimientos disciplinarios'
          }
        }
      ]
    },
    { //Criminal Law
      title: {
        en: 'Criminal Law',
        es: 'Derecho Penal'
      },
      image: 'criminal.webp',
      description: {
        en: 'Providing legal advice and judicial assistance in criminal matters, including representation and defense of victims of crimes.',
        es: 'Proporcionando asesoramiento legal y asistencia judicial en asuntos penales, incluyendo representación y defensa de víctimas de delitos.'
      },
      services: [
        {
          id: 1,
          name: {
            en: 'Legal advice and judicial assistance in criminal matters',
            es: 'Asesoramiento legal y asistencia judicial en asuntos penales'
          }
        },
        {
          id: 2,
          name: {
            en: 'Representation and defense of victims of crimes',
            es: 'Representación y defensa de víctimas de delitos'
          }
        },
        {
          id: 3,
          name: {
            en: 'Corporate crimes',
            es: '"Delitos empresariales'
          }
        },
        {
          id: 4,
          name: {
            en: 'Crimes against industrial and intellectual property',
            es: '"Delitos contra la propiedad intelectual'
          }
        },
        {
          id: 5,
          name: {
            en: 'Crimes against fundamental rights and honor',
            es: 'Delitos contra derechos fundamentales y honor'
          }
        }
      ]
    },
    { //Notary Law
      title: {
        en: 'Notarial Services',
        es: 'Servicios Notariales'
      },
      image: 'notary.jpg',
      description: {
        en: 'Offering a range of notary services including vehicle transfers, authentication of signatures and documents, drafting of purchase and sale deeds, among others.',
        es: 'Ofreciendo una variedad de servicios notariales que incluyen traspaso de vehículos, autenticación de firmas y documentos, redacción de escrituras de compra y venta, entre otros.'
      },
      services: [
        {
          id: 1,
          name: {
            en: 'Vehicle transfer',
            es: 'Traspaso de Vehículo'
          }
        },
        {
          id: 2,
          name: {
            en: 'Signature authentication',
            es: 'Auténticas de Firma'
          }
        },
        {
          id: 3,
          name: {
            en: 'Authentication of document copies',
            es: 'Autentica de Copias de Documentos'
          }
        },
        {
          id: 4,
          name: {
            en: 'Purchase and sale deeds',
            es: 'Escrituras de Compra y Venta'
          }
        },
        {
          id: 5,
          name: {
            en: 'Constitution of individual merchants',
            es: 'Constitución de Comerciantes Individuales'
          }
        },
        {
          id: 6,
          name: {
            en: 'Constitution of companies',
            es: 'Constitución de Sociedades'
          }
        },
        {
          id: 7,
          name: {
            en: 'Drafting of notarial acts',
            es: 'Levantamiento de Actas Notariales'
          }
        },
        {
          id: 8,
          name: {
            en: 'Drafting of solemn inventories',
            es: 'Levantamiento de inventario solemne'
          }
        },
        {
          id: 9,
          name: {
            en: 'General powers of administration',
            es: 'Poderes General de Administración'
          }
        },
        {
          id: 10,
          name: {
            en: 'Special powers (banking transactions, collections)',
            es: 'Poder Especial (Actuaciones Bancarias, Cobros)'
          }
        },
        {
          id: 11,
          name: {
            en: 'Power of attorney for litigation',
            es: 'Poder Para Pleitos'
          }
        },
        {
          id: 12,
          name: {
            en: 'Power of attorney letter (administrative acts)',
            es: 'Carta Poder (Actos Administrativos —SAR, IP, Placas, AMDC, otros)'
          }
        },
        {
          id: 13,
          name: {
            en: 'Sworn statement',
            es: 'Declaración jurada'
          }
        },
        {
          id: 14,
          name: {
            en: 'Civil contracts',
            es: 'Contratos Civiles'
          }
        },
        {
          id: 15,
          name: {
            en: 'Commercial contracts',
            es: 'Contratos Mercantiles'
          }
        },
        {
          id: 16,
          name: {
            en: 'Voluntary emancipation',
            es: 'Emancipación Voluntaria'
          }
        },
        {
          id: 17,
          name: {
            en: 'Alienation of minors\' property',
            es: 'Enajenación de Bienes De Menores'
          }
        },
        {
          id: 19,
          name: {
            en: 'Property partition',
            es: 'Partición de Bienes'
          }
        },
        {
          id: 20,
          name: {
            en: 'Wills and testaments',
            es: 'Testamentos'
          }
        },
        {
          id: 21,
          name: {
            en: 'Authorization for travel or departure of a minor from the country - Both Parents Authorize',
            es: 'Autorización de Viaje o Salida de Menor del País - Ambos Padres autorizan'
          }
        },
        {
          id: 22,
          name: {
            en: 'Authorization for travel or departure of a minor from the country - Mother or Father Authorizes',
            es: 'Autorización de Viaje o Salida de Menor del País - Madre o Padre Autoriza'
          }
        },
        {
          id: 23,
          name: {
            en: 'Authorization for travel or departure of a minor from the country - Mother or Father Authorizes to a Third Party',
            es: 'Autorización de Viaje o Salida de Menor del País - Madre o Padre Autoriza a Tercero'
          }
        },
        {
          id: 24,
          name: {
            en: 'Authorization for travel or departure of a minor from the country - Unaccompanied - Both Parents Authorize',
            es: 'Autorización de Viaje o Salida de Menor del País - Sin Acompañante - Ambos Padres Autorizan'
          }
        },
        {
          id: 25,
          name: {
            en: 'Authorization for travel or departure of a minor from the country - Unaccompanied - Father or Mother Authorizes',
            es: 'Autorización de Viaje o Salida de Menor del País - Sin Acompañante Padre o Madre Autoriza'
          }
        },
        {
          id: 26,
          name: {
            en: 'Authorization for departure from the country for a vehicle - Legal Entity',
            es: 'Autorización de Salida del País Para Vehículo - Persona Jurídica'
          }
        },
        {
          id: 27,
          name: {
            en: 'Authorization for departure from the country for a vehicle - Natural Person',
            es: 'Autorización de Salida del País Para Vehículo • Persona Natural'
          }
        }
      ]
    },
    { //Administrative Law
      title: {
        en: 'Administrative Law ',
        es: 'Derecho Administrativo'
      },
      image: 'administrative.jpg',
      description: {
        en: 'Providing administrative services related to civil matters.',
        es: 'Proporcionando servicios administrativos relacionados con asuntos del civiles.'
      },
      services: [
        {
          id: 17,
          name: {
            en: ' Environmental Licensing',
            es: ' Licencias Ambientales'
          }
        },
        {
          name: {
            en: 'Category I, Category II, Category III, Category IV',
            es: 'Categoria I, Categoria II, Categoria III, Categoria IV'
          }
        },
        {
          name: {
            en: ' Health Licensing',
            es: ' Licencias Sanitarias'
          }
        },
        {
          id: 18,
          name: {
            en: 'We offer representation in the processes of New Health Registrations, Renewals, Modifications:',
            es: 'Ofrecemos representación en los trámites de Registros Sanitarios Nuevos, Renovaciones, Modificaciones:'
          }
        },
        {
          id: 19,
          name: {
            en: 'Foods, Beverages, Medicines: Homeopathic, Biological, Radiopharmaceuticals, Biotechnological, Nutritional Supplements, Galenic.Naturals, Cosmetics, Hygienic, Pesticides, Medical Devices',
            es: 'Alimentos, Bebidas, Medicamentos: Homeopáticos, Biológicos, Radiofármacos, Biotecnológicos, Suplementos Nutricionales, Galénicos.Naturales, Cosméticos, Higiénicos, Plaguicidas, Dispositivos Médicos'
          }
        },
        {
          name: {
            en: ' Civil Registry ',
            es: ' Registro de las Personas '
          }
        },
        {
          name: {
            en: 'Replacement due to omission of birth registration',
            es: 'Reposición por Omisión de Inscripción de nacimiento'
          }
        },
        {
          id: 2,
          name: {
            en: 'Recognition of Minors',
            es: 'Reconocimiento de Menores'
          }
        },
        {
          id: 3,
          name: {
            en: 'Correction of names and surnames',
            es: 'Corrección y rectificación de nombres y apellidos'
          }
        },
        {
          id: 5,
          name: {
            en: 'Registration of Hondurans born abroad',
            es: 'Inscripción de hondureños nacidos en el Extranjero'
          }
        },
        {
          id: 6,
          name: {
            en: 'Registration of Marriage of Hondurans abroad',
            es: 'Inscripción de Matrimonio de hondureños en el Extranjero'
          }
        },
        {
          id: 7,
          name: {
            en: 'Replacement due to omission of Marriage Registration',
            es: 'Reposición por Omisión de la Inscripción De Matrimonio'
          }
        },
        {
          id: 9,
          name: {
            en: 'Cancellation for double registration',
            es: 'Cancelacion por doble inscripcion'
          }
        }
      ]
    }    
  ];
  