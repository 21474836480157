<template>
    <div id="contact">
        <div class="container">
            <div class="text">
                {{ contactLabels.MainLabels.title[currentLanguage] }}
            </div>
            <div class="subtext">
                {{ contactLabels.MainLabels.subtitle[currentLanguage] }}
            </div>
            <form id="formy" @submit.prevent="sendEmail">
              <p style="text-align: left;">{{ contactLabels.MainLabels.plaintiff[currentLanguage] }}</p>
              <div class="form-row">
                  <div class="input-data">
                      <input type="text" required id="plaintiffFullName">
                      <div class="underline"></div>
                      <label for="">{{ contactLabels.plaintiff.fullName[currentLanguage] }}</label>
                  </div>
                  <div class="input-data">
                      <input type="text" required id="plaintiffIdPassport">
                      <div class="underline"></div>
                      <label for="">{{ contactLabels.plaintiff.idPassport[currentLanguage] }}</label>
                  </div>
              </div>

              <div class="form-row">
                  <div class="input-data">
                      <input type="text" required id="plaintiffAddress">
                      <div class="underline"></div>
                      <label for="">{{ contactLabels.plaintiff.address[currentLanguage] }}</label>
                  </div>
                  <div class="input-data">
                      <input type="text" required id="plaintiffProfession">
                      <div class="underline"></div>
                      <label for="">{{ contactLabels.plaintiff.profession[currentLanguage] }}</label>
                  </div>
              </div>

              <div class="form-row">
                  <div class="input-data">
                      <input type="text" required id="plaintiffPhoneNumber">
                      <div class="underline"></div>
                      <label for="">{{ contactLabels.plaintiff.phoneNumber[currentLanguage] }}</label>
                  </div>
                  <div class="input-data">
                      <input type="text" required id="plaintiffEmail">
                      <div class="underline"></div>
                      <label for="">{{ contactLabels.plaintiff.email[currentLanguage] }}</label>
                  </div>
              </div>

              <p style="text-align: left;">{{ contactLabels.MainLabels.defendant[currentLanguage] }}</p>
              <div class="form-row">
                  <div class="input-data">
                      <input type="text" id="defendantFullName">
                      <div class="underline"></div>
                      <label for="">{{ contactLabels.defendant.fullName[currentLanguage] }}</label>
                  </div>

                  <div class="input-data">
                      <div class="dropdown">
                          <select id="relationship">
                              <option v-for="(relationship, index) in contactLabels.defendant.relationship[currentLanguage]" :key="index">{{ relationship }}</option>
                          </select>
                      </div>
                  </div>
              </div>

              <p style="text-align: left;">{{ contactLabels.MainLabels.issue[currentLanguage] }}</p>
              <div class="form-row">
                  <div style="top: -10px;" class="input-data">
                      <div class="dropdown">
                          <select id="issue">
                              <option v-for="(relationship, index) in contactLabels.issue[currentLanguage]" :key="index">{{ relationship }}</option>
                          </select>
                      </div>
                  </div>
              </div>

              <div class="form-row">
                  <div class="input-data textarea">
                      <textarea rows="8" cols="80" required id="message"></textarea>
                      <br />
                      <div class="underline"></div>
                      <label for="">{{ contactLabels.message[currentLanguage] }}</label>
                  </div>
              </div>

              <div class="form-row">
                  <div class="form-row submit-btn">
                      <div class="input-data">
                          <div class="inner"></div>
                          <input type="submit" value="submit">
                      </div>
                  </div>
              </div>
          </form>

        </div>

        <div class="info">

          <div class="toppy">
            <h4>{{ contactLabels.infoMessage.title[currentLanguage] }}</h4>
            <div class="subtext">{{ contactLabels.infoMessage.parragraph[currentLanguage] }}</div>
          </div>

          <div class="rowy">

            <div class="toppy">
              <h4>{{ contactLabels.infoPhone.title[currentLanguage] }}</h4>
              <div class="subtext">{{ contactLabels.infoPhone.parragraph[currentLanguage] }}</div>
            </div>
            <div class="toppy">
              <h4>{{ contactLabels.infoEmail.title[currentLanguage] }}</h4>
              <div class="subtext">{{ contactLabels.infoEmail.parragraph[currentLanguage] }}</div>
            </div>
            <div class="toppy">
              <h4>{{ contactLabels.infoHours.title[currentLanguage] }}</h4>
              <div class="subtext">{{ contactLabels.infoHours.parragraph[currentLanguage] }}</div>
              <div class="subtext">{{ contactLabels.infoHours.hours[currentLanguage] }}</div>
            </div>

          </div>

      </div>

        <div v-if="success" class="popsuccess">
          <i style="color: green;" class="fa-solid fa-circle-check"></i>
          <h3 style="color: green;">{{currentLanguage==='en' ? "Sent" : "Exito"}}</h3>
        </div>

    </div>
</template>

<script>
import contactLabels from "../assets/textfiles/contact.js";
import emailjs from '@emailjs/browser';

export default {
  props: ['currentLanguage'],
  data() {
    return {      
      success: false,
      contactLabels: contactLabels,
      serviceId: 'service_sortolegal',    
      templateId: 'template_contacto'
    }
  },
  methods: {
    sendEmail() {      
      //Prepare the email parameters
      const params = {
        plain_name : document.getElementById("plaintiffFullName").value,
        plain_id : document.getElementById("plaintiffIdPassport").value,
        plain_address : document.getElementById("plaintiffAddress").value,
        plain_pro : document.getElementById("plaintiffProfession").value,
        plain_number : document.getElementById("plaintiffPhoneNumber").value,
        plain_email : document.getElementById("plaintiffEmail").value,
        def_name : document.getElementById("defendantFullName").value,
        def_rel : document.getElementById("relationship").value,
        issue_type : document.getElementById("issue").value,
        message : document.getElementById("message").value
      };
      
      const form = document.getElementById("formy");

      // Send the email using EmailJS
      emailjs
        .send(this.serviceId, this.templateId, params, {
          publicKey: 'G6ILzncS11LzqrPvz',
        })
        .then(
          () => {
            console.log('SUCCESS!');
            form.reset();
            this.success = true;
            // Start a timer to hide the popup after 3 seconds
              setTimeout(() => {
                this.success = false;
              }, 3000);
            },
          (error) => {
            console.log('FAILED...', error.text);
          },
        );
    }
  }
}
</script>

<style>
    #contact{
        background-color: #002741;
        width: 100%;
        height: 100%;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .info {
      background-color: #00000063;
      border-radius: 20px;
      padding: 2%;
    }

    .popsuccess{
      position: fixed;
      display: flex;
      z-index: 100;
      
      bottom: 0%;
      top: 50%;

      background-color: white;
      box-shadow: 0px 0px 10px #00000085;
      border-radius: 20px;
      width: 150px;
      height: 150px;

      justify-content: center;
      align-items: center;
      flex-direction: column;
    }

    .container{
        max-width: 800px;
        height: 80%;
        background: #002741;
        width: 800px;
        padding: 25px 40px 10px 40px;
        display: flex;
        flex-direction: column;
    }
    .container .text{
        text-align: center;
        font-size: 41px;
        font-weight: 600;
        font-family: 'Poppins', sans-serif;
    }

    .subtext{
        text-align: center;
        font-size: 20px;
        font-weight: 400;
        font-style: italic;
        color: #ffffff96;
        margin: 0%;
        padding-bottom: 5%;
    }

.container form{
  padding: 10px;
}
.container form .form-row{
  display: flex;
  margin: 10px 0;
  width: 100%;
}
form .form-row .input-data{
  width: 100%;
  height: 40px;
  margin: 0 20px;
  position: relative;
}
form .form-row .textarea{
  height: 70px;
}
.input-data input,
.textarea textarea{
  display: block;
  width: 100%;
  height: 100%;
  border: none;
  font-size: 17px;
  border-bottom: 2px solid rgba(0,0,0, 0.12);
}
.input-data input:focus ~ label, .textarea textarea:focus ~ label,
.input-data input:valid ~ label, .textarea textarea:valid ~ label{
  transform: translateY(-10px);
  font-size: 10px;
  color: #3498db;
}
.textarea textarea{
  resize: none;
  padding-top: 10px;
  width: 100%;
}
.input-data label{
  position: absolute;
  pointer-events: none;
  left: 5px;
  bottom: 3%;
  font-size: 16px;
  transition: all 0.3s ease;
  color: #00274179;
}
.textarea label{
  width: 100%;
  bottom: 40px;
  text-align: left;
}
.input-data .underline{
  position: absolute;
  bottom: -5px;
  left: -50%;
  height: 2px;
  width: 100%;
}
.input-data .underline:before{
  position: absolute;
  content: "";
  height: 2px;
  width: 100%;
  background: #3498db;
  transform: scaleX(0);
  transform-origin: center;
  transition: transform 0.3s ease;
}
.input-data input:focus ~ .underline:before,
.input-data input:valid ~ .underline:before,
.textarea textarea:focus ~ .underline:before,
.textarea textarea:valid ~ .underline:before{
  transform: scale(1);
}
.submit-btn .input-data{
  overflow: hidden;
  height: 45px!important;
  width: 100%!important;
}
.submit-btn .input-data .inner{
  height: 100%;
  width: 300%;
  position: absolute;
  left: -100%;
  background: -webkit-linear-gradient(right, #56d8e4, #9f01ea, #56d8e4, #9f01ea);
  transition: all 0.4s;
}
.submit-btn .input-data:hover .inner{
  left: 0;
}
.submit-btn .input-data input{
  background: none;
  border: none;
  color: #fff;
  font-size: 17px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;
  position: relative;
  z-index: 2;
}

.input-data .dropdown{
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: flex-start;
    align-items: stretch;
}

@media (max-width: 700px) {
  #contact{
    padding-top: 20px;
        flex-direction: column-reverse;
    }

    .info {
      margin-left: 7%;
      width: 80%;
      padding-left: 5%;
    }

  .container{
        width: 70%;
        height: 100%;
        background: #002741;
        padding: 25px 40px 10px 40px;
        display: flex;
        flex-direction: column;
    }


  .container .text{
    font-size: 30px;
  }
  .container form{
    padding: 10px 0 0 0;
  }
  .container form .form-row{
    display: block;
  }
  form .form-row .input-data{
    margin: 35px 0!important;
  }
  .submit-btn .input-data{
    width: 40%!important;
  }
}

</style>
