export default [
    {
        about: { 
            icon:'fa-regular fa-circle-user',
            link: '#about',
            label: {
                en: 'About', 
                es: 'Acerca de' 
            }
        },
        meet: { 
            icon:'fa-regular fa-phone-volume',
            link: '#Meet',
            label: {
            en: 'FREE Meeting', 
            es: 'Agenda una reunion' 
            }
        },
        services: { 
            icon:'fa-regular fa-list-check',
            link: '#services',
            label: {
            en: 'Services', 
            es: 'Servicios' 
            }
        },
        /*faqs: { 
            icon:'fa-regular fa-circle-question',
            link: '#faqs',
            label: {
            en: 'FAQs', 
            es: 'Preguntas frecuentes' 
            }
        },
        /*testimonials: { 
            icon:'fa-regular fa-comments',
            link: '#testimonials',
            label: {
            en: 'Testimonials', 
            es: 'Testimonios' 
            }
        },*/
        contact: { 
            icon:'fa-regular fa-envelope',
            link: '#contact',
            label: {
            en: 'Contact', 
            es: 'Contact' 
            }
        },
    },
]