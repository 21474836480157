<template>
    <div id="services">
        <div class="carousel">
            <!-- list item -->
            <div  class="list" v-for="(area, index) in areas" :key="area.title[currentLanguage]">
                <div class="slide" v-show="index === currentSlide">
                    <img :src="require('../assets/images/servicios/' + area.image)">
                    <div class="slide-content">
                        <div class="title">{{ area.title[currentLanguage] }}</div>
                        <div class="des">{{ area.description[currentLanguage] }}</div>
                        <div class="buttons">
                            <button @click="openDetailsModal(area.services)">{{currentLanguage==='en' ? "SERVICES" : "SERVICIOS"}}</button>
                        </div>
                    </div>
                </div>
            </div>
            
            <!-- THUMBNAILS -->
            <div class="thumbnails" ref="thumbnailsContainer">
                <div class="thumbnail"
                    v-for="(area, index) in areas"
                    @click="changeSlide(index)"
                    :key="area.title[currentLanguage]"
                    :class="{ 'selectedthumbnail': index === currentSlide }"
                    :style="{ 'opacity': calculateOpacity(index) }"
                    :ref="`thumbnail${index}`">
                    <img :src="require('../assets/images/servicios/' + area.image)">
                    <div class="title">{{ area.title[currentLanguage] }}</div>
                </div>
            </div>


            <!-- MODAL -->
            <div class="Modal" v-if="showModal">
                <div style="display: flex; flex-direction: row; align-items: center; gap: 5%;">
                    <i @click="closeDetailsModal" class="fas fa-times-circle"></i>
                    <h4>{{currentLanguage==='en' ? "Services" : "Servicios"}}</h4>
                </div>
                <ul style="font-size: 1rem; width: 80%; overflow-y: scroll;">
                    <li v-for="service in modalContent" :key="service.id" :class="{ 'line-break': service.name[currentLanguage].startsWith(' ') }">
                        {{ service.name[currentLanguage] }}
                    </li>
                </ul>
            </div>
        
        </div>
    </div>
</template>

<script>
/*import DetailsModal from './DetailsModal.vue';*/
import services from '../assets/textfiles/services.js';

export default {
    props: ['currentLanguage'],
    data() {
        return {
            areas: services,
            currentSlide: 0,
            showModal: false,
            modalContent: null,            
            userInteracted: false, // Variable to track user interaction
            slideTimer: null // Timer for automatic slide change
        };
    },
    methods: {
        openDetailsModal(services) {
            this.modalContent = services;
            this.showModal = true;
            this.userInteracted = true; // User interacted when opening the details modal
            this.stopSlideTimer(); // Stop the slide timer
        },
        closeDetailsModal() {
            this.showModal = false;
            this.userInteracted = true; // User interacted when closing the details modal
            this.startSlideTimer(); // Restart the slide timer
        },
        changeSlide(index) {
            this.showModal = false;
            this.currentSlide = index;
            this.userInteracted = true; // User interacted when changing the slide manually
            this.stopSlideTimer(); // Stop the slide timer
            this.centerThumbnails();
        },
        calculateOpacity(index) {
            // Calculate opacity based on distance from currentSlide
            const distance = Math.abs(index - this.currentSlide);
            let opacity = 1 - distance * 0.25; // Adjust the multiplier as needed
            opacity = Math.max(opacity, 0.2); // Clamp opacity to minimum value of 0.2
            return opacity;
        },
        centerThumbnails() {
            // Find the thumbnail element corresponding to the current slide
            const thumbnailRef = this.$refs[`thumbnail${this.currentSlide}`];
            
            if (thumbnailRef && thumbnailRef.length > 0) {
                const thumbnail = thumbnailRef[0];
                const thumbnailsContainer = this.$refs.thumbnailsContainer;
                const containerWidth = thumbnailsContainer.offsetWidth;
                const thumbnailWidth = thumbnail.offsetWidth;
                const scrollLeft = thumbnail.offsetLeft - (containerWidth / 2) + (thumbnailWidth / 2);
                thumbnailsContainer.scrollLeft = scrollLeft;
            }
        },
        startSlideTimer() {
            // Start the slide timer if user hasn't interacted
            if (!this.userInteracted) {
                this.slideTimer = setInterval(() => {
                    // Increment the current slide index
                    this.currentSlide = (this.currentSlide + 1) % this.areas.length;
                    
                    // Center the thumbnails after changing the slide
                    this.centerThumbnails();
                }, 5000);
            }
        },
        stopSlideTimer() {
            // Stop the slide timer
            clearInterval(this.slideTimer);
        }
    },
    mounted() {
        // Start the slide timer when the component is mounted
        this.startSlideTimer();
    },
    beforeUnmount() {
        // Clear the slide timer when the component is destroyed
        this.stopSlideTimer();
    }
}

</script>


<style>
.line-break {
    font-weight: bold;
}


.Modal{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;

    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    right: 5%;
    padding: 2%;
    z-index: 100;

    min-height: 60%;
    max-height: 80%;
    max-width: 30%;

    background-color: #ffffff8f;
    backdrop-filter: blur(10px);
    border-radius: 20px;
    color: #002741;
    gap: 2%;
}

#services {
    position: relative;
    min-height: 100%;
    min-width: 100%;
    display: flex;
    align-items: center;
}

.carousel {
    position: relative;
    min-width: 100%;
    min-height: 100vh;
    overflow: hidden;
}

.carousel .list .slide{
    width: 100%;
    height: 100%;
    position: absolute;
    inset: 0 0 0 0;
    background-color: #000;
}
.carousel .list .slide img{
    opacity: .7;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.carousel .list .slide .slide-content{
    position: absolute;
    top: 20%;
    width: 75%;
    max-width: 75%;
    left: 50%;
    transform: translateX(-50%);
    padding-right: 30%;
    box-sizing: border-box;
    color: #fff;
    text-shadow: 0 5px 10px #0004;
}

.carousel .list .slide .title{
    font-size: 150%;
    font-weight: bold;
    line-height: 1em;
    text-shadow: 0px 0px 10px #000;
    padding-bottom: 2%;
}
.carousel .list .slide .des{
    font-size: 1.3rem;
    text-shadow: 0px 0px 10px #000;
}
.carousel .list .slide .buttons{
    display: grid;
    grid-template-columns: repeat(2, 130px);
    grid-template-rows: 40px;
    gap: 5px;
    margin-top: 20px;
}
.carousel .list .slide .buttons button{
    border: none;
    display: flex;
    letter-spacing: 3px;
    font-weight: 500;
    padding: 10px 85px;
    background-color: #002741;
    border-radius: 50px;
    color: #ffffff;
    justify-content: center;
    align-items: center;
}
.carousel .list .slide .buttons button:nth-child(2){
    background-color: transparent;
    border: 1px solid #fff;
    color: #eee;
}
/* thumbail */
.thumbnails{
    position: absolute;
    display: flex;
    flex-direction: row;

    left: 50%;
    transform: translateX(-50%);
    bottom: 50px;
    width: 50%;
    height: 15%;
    z-index: 10;
    gap: 1%;
    padding-left: 10%;
    
    overflow-x: auto;
    overflow-y: visible;

    scroll-behavior: smooth;
}

.thumbnails::-webkit-scrollbar {
    display: none;
}

.thumbnail{
    border-radius: 20px;
    width: 20%;
    height: 90%;
    flex-shrink: 0;
    position: relative;
    overflow: hidden;

    box-shadow: 0px 0px 5px #000;
}

.selectedthumbnail{
    border-radius: 20px;
    width: 25%;
    height: 95%;
    flex-shrink: 0;
    position: relative;
    overflow: hidden;

    box-shadow: 0px 0px 5px #000;
    border-style: solid;
    border-width: 2px;
    border-color: #eee;
}

.thumbnail img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.thumbnail .title{
    position: absolute;
    bottom: 0%;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    z-index: 10;
    color: #fff;
    text-shadow: 0px 0px 5px #000;
    font-weight: 500;
    font-size: 14px;
    width: 90%;
}

/* animation */
.carousel .list .item:nth-child(1){
    z-index: 1;
}

/* animation text in first item */

.carousel .list .item:nth-child(1) .content .title,
.carousel .list .item:nth-child(1) .content .des,
.carousel .list .item:nth-child(1) .content .buttons
{
    transform: translateY(50px);
    filter: blur(20px);
    opacity: 0;
    animation: showContent .5s 1s linear 1 forwards;
}
@keyframes showContent{
    to{
        transform: translateY(0px);
        filter: blur(0px);
        opacity: 1;
    }
}
.carousel .list .item:nth-child(1) .content .title{
    animation-delay: 1.2s!important;
}
.carousel .list .item:nth-child(1) .content .des{
    animation-delay: 1.6s!important;
}
.carousel .list .item:nth-child(1) .content .buttons{
    animation-delay: 1.8s!important;
}
/* create animation when next click */
.carousel.next .list .item:nth-child(1) img{
    width: 150px;
    height: 220px;
    position: absolute;
    bottom: 50px;
    left: 50%;
    border-radius: 30px;
    animation: showImage .5s linear 1 forwards;
}
@keyframes showImage{
    to{
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 0;
    }
}

.carousel.next .thumbnail .item:nth-last-child(1){
    overflow: hidden;
    animation: showThumbnail .5s linear 1 forwards;
}
.carousel.prev .list .item img{
    z-index: 100;
}
@keyframes showThumbnail{
    from{
        width: 0;
        opacity: 0;
    }
}
.carousel.next .thumbnail{
    animation: effectNext .5s linear 1 forwards;
}

@keyframes effectNext{
    from{
        transform: translateX(150px);
    }
}

/* running time */

.carousel .time{
    position: absolute;
    z-index: 1000;
    width: 0%;
    height: 3px;
    background-color: #f1683a;
    left: 0;
    top: 0;
}

.carousel.next .time,
.carousel.prev .time{
    animation: runningTime 3s linear 1 forwards;
}
@keyframes runningTime{
    from{ width: 100%}
    to{width: 0}
}


/* prev click */

.carousel.prev .list .item:nth-child(2){
    z-index: 2;
}

.carousel.prev .list .item:nth-child(2) img{
    animation: outFrame 0.5s linear 1 forwards;
    position: absolute;
    bottom: 0;
    left: 0;
}
@keyframes outFrame{
    to{
        width: 150px;
        height: 220px;
        bottom: 50px;
        left: 50%;
        border-radius: 20px;
    }
}

.carousel.prev .thumbnail .item:nth-child(1){
    overflow: hidden;
    opacity: 0;
    animation: showThumbnail .5s linear 1 forwards;
}
.carousel.next .arrows button,
.carousel.prev .arrows button{
    pointer-events: none;
}
.carousel.prev .list .item:nth-child(2) .content .title,
.carousel.prev .list .item:nth-child(2) .content .des,
.carousel.prev .list .item:nth-child(2) .content .buttons
{
    animation: contentOut 1.5s linear 1 forwards!important;
}

@keyframes contentOut{
    to{
        transform: translateY(-150px);
        filter: blur(20px);
        opacity: 0;
    }
}
@media screen and (max-width: 768px) {

    .carousel .list .slide img{
        object-position: 14%;
    }

    .carousel .list .slide .slide-content{
        left: 55%;
    }
    .thumbnails{
    left: 40%;
    gap: 5%;
    }

    .thumbnail .title{
        font-size: 10px;
    bottom: 50%;
    left: 5%;
    transform: translateY(50%);
    width: 90%;
    }

    .Modal{
        top: 50%;
        left: 20%;

        min-height: 60%;
        max-height: 80%;
        min-width: 70%;
    }
    .carousel .list .slide .title{
        font-size: 100%;
        padding-bottom: 5%;
    }
    .carousel .list .slide .des{
        font-size: 1.2rem;
        line-height: 1.5rem;
        text-shadow: 0px 0px 8px #000;
    }

}



</style>