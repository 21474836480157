<template>
    <div id="hero">
      <img src="../assets/images/logoblue.png" id="logo">
      <div class="text-container">
          <h2>{{ HeroArray[0].header[currentLanguage] }}</h2>
          <h1>{{ HeroArray[0].title[currentLanguage] }}</h1>
          <p>{{ HeroArray[0].description[currentLanguage] }}</p>
          <a href="#contact" class="cta">{{ HeroArray[0].CTA[currentLanguage] }}</a>
      </div>
</div>

</template>

<script>
import Herofile from '../assets/textfiles/hero.js';

export default {
  props: ['currentLanguage'],
    data() {
        return {
            HeroArray: Herofile,
        };
    },
}
</script>

<style>

#hero {
  background-color: #002741;
  width: 100%;
  min-height: 100%;
  background-image: url("../assets/images/bg02.png");
  background-size: cover;
  padding: 5em;
  box-sizing: border-box;
  position: relative;
}

#logo {
  position: absolute;
  top: 5%;
  width: 30%;
  left: 20%;
  opacity: 0.5;
  animation: floatLogo 3s infinite alternate;
}

.text-container {
  position: absolute;
  left: 10%;
  top: 40%;
  transform: translateY(-50%);
  animation: enterText 1.5s forwards;
}

.text-container h2 {
  font-size: 2.5rem;
  color: #002741;
}

.text-container h1 {
  font-size: 5rem;
  color: #000000;
}

.text-container p {
  font-size: 1.3rem;
  color: #575757;
}

.cta {
  display: inline-block;
  padding: 10px 35px;
  background-color: #002741;
  border-radius: 50px;
  color: #ffffff;
  text-decoration: none;
  margin-top: 20px;
}

@keyframes floatLogo {
  0% {
    transform: translateY(10);
  }
  100% {
    transform: translateY(25px);
  }
}

@keyframes enterText {
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Responsive design */
@media (max-width: 768px) {

    #hero {
        background-size: cover;
        background-position-x: 80%;
    }

  #logo {
    width: 50%;
    left: 30%; 
    opacity: 0.3;
    top: 40%;
  }

  .text-container {
    padding-right: 5%;
    padding-top: 5%;
    padding-bottom: 5%;
    top: 55%;
    bottom: 0%;
    text-align: right;
    background-color: #ffffff59;
  }

  .text-container h2 {
    font-size: 1.5rem;
  }

  .text-container h1 {
    font-size: 3rem;
  }

  .text-container p {
    font-size: 1rem; 
    color: #000000;
  }
}
</style>
