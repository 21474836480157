<template>
    <div class="translatebutton" @click="toggleLanguage">
        <img class="Flag" :src="getImageSource" alt="Language Image">
        <p>{{currentLanguage==='en' ? "ENGLISH" : "SPANISH"}}</p>
        <i class= "fa-regular fa-language "></i>
    </div>

    <a :href="whatsappLink" target="_blank" class="floaters">
            <i style="color: #fff;" class="fab fa-whatsapp"></i>
            <span class="tooltip">{{ currentLanguage === 'en' ? 'Send me a message' : 'Envíame un mensaje' }}</span>
    </a>

    <a :href="InstaLink" target="_blank" class="Ifloaters">
            <i style="color: #fff;" class="fab fa-instagram"></i>
    </a>

    <a :href="TiktokLink" target="_blank" class="Tikfloaters">
            <i style="color: #fff;" class="fab fa-tiktok"></i>
    </a>

     <nav id="navbar">
        <ul class="navbar-items flexbox-col">
        <li class="navbar-logo flexbox-left">
            <a class="navbar-item-inner flexbox" href="#hero">
            <img src="../assets/images/logowhite.png" width="50px">
            </a>
        </li>

        <li class="navbar-item flexbox-left" v-for="(nav, key) in NavArray[0]" :key="key">
            <a class="navbar-item-inner flexbox-left" :href="nav.link">
                <div class="navbar-item-inner-icon-wrapper flexbox">
                    <i :class="nav.icon"></i>
                </div>
                <span class="link-text">{{ nav.label[currentLanguage] }}</span>
            </a>
        </li>

        </ul>
    </nav>
</template>

<script>
import Navbarfile from '../assets/textfiles/navbar.js';

export default {
    props: ['currentLanguage'],
    data() {
        return {
            NavArray: Navbarfile
        };
    },
    methods: {
    toggleLanguage() {
      const newLanguage = this.currentLanguage === 'en' ? 'es' : 'en';
      this.$emit('changeLanguage', newLanguage);
    }
  },
  computed: {
    getImageSource() {
      return this.currentLanguage === 'en' ? require('@/assets/images/ENG.png') : require('@/assets/images/SPA.webp');
    },
    whatsappLink() {
        return 'https://wa.me/50496450625';
    },
    InstaLink() {
        return 'https://www.instagram.com/sortolegal/';
    },
    TiktokLink() {
        return 'https://www.tiktok.com/@sortolegal?_t=8leySiW7UcI&_r=1';
    }
}

}
</script>


<style>
.translatebutton{
    cursor: pointer;
    display: flex;
    position: absolute;
    z-index: 100;
    top: 5%;
    right: 5%;
    background-color:#000;
    color: #fff;
    padding: 1% 2%;
    gap: 10px;
    align-items: center;
    border-radius: 40px;
    overflow: hidden;
}

.translatebutton:hover{
    box-shadow: 0px 5px 3px #000000c2;
}

.translatebutton .Flag{
    position: absolute;
    width: 100%;
    left: 0%;
    opacity: 0.8;
    z-index: -10;
}

.floaters {
    cursor: pointer;
    position:fixed;
    background-color: #25d366;
    color: #fff;
    font-size: 40px;
    bottom: 8%;
    right: 4%;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    z-index: 1000;
    text-align: center;
    align-content: center;
}

.floaters:hover{
    box-shadow: 0px 5px 3px #000000c2;
}

.tooltip {
    font-size: small;
    width: 120px;
    background-color: #000;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 3px;
    position: absolute;
    z-index: 1;
    bottom: 120%;
    left: 50%;
    margin-left: -60px;
}

.Ifloaters {
    cursor: pointer;
    position:fixed;
    background-color: #002741;
    color: #fff;
    font-size: 40px;
    bottom: 8%;
    right: 7.5%;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    z-index: 1000;
    text-align: center;
    align-content: center;
}

.Ifloaters:hover{
    box-shadow: 0px 5px 3px #000000c2;
}

.Tikfloaters {
    cursor: pointer;
    position:fixed;
    background-color: black;
    color: #fff;
    font-size: 40px;
    bottom: 8%;
    right: 11%;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    z-index: 1000;
    text-align: center;
    align-content: center;
}

.Tikfloaters:hover{
    box-shadow: 0px 5px 3px #000000c2;
}

#navbar {
    z-index: 100;
    top: 0%;
    left: 0%;
    padding: 0;
    width: 4em;
    height: 100%;
    position: fixed;
    background-color: rgba(0, 40, 66, 0.8);
    transition: width .35s cubic-bezier(var(--transition-main), 1);
    backdrop-filter: blur(10px);
    overflow-y: auto;
    overflow-x: hidden;
}
#navbar:hover {
    width: 12em;
}
#navbar::-webkit-scrollbar-track {
    background-color: hsl(var(--background-secondary));
}
#navbar::-webkit-scrollbar {
    width: 8px;
    background-color: hsl(var(--background-secondary));
}
#navbar::-webkit-scrollbar-thumb {
    background-color: hsl(var(--primary));
}
.navbar-items {
    margin: 0;
    padding: 0;
    list-style-type: none;
}
/* Navbar Logo */
.navbar-logo {
    margin: 0 0 2em 0;
    width: 100%;
    height: 5em;
    background: hsl(var(--background-secondary-dark));
}
.navbar-logo > .navbar-item-inner {
    width: calc(5rem - 8px);
}
.navbar-logo > .navbar-item-inner:hover {
    background-color: transparent;
}
.navbar-logo > .navbar-item-inner > svg {
    height: 2em;
    fill: hsl(var(--white));
}
/* Navbar Items */
.navbar-item {
    padding: 0 .5em;
    width: 100%;
    cursor: pointer;
}
.navbar-item-inner {
    padding: 1em 0;
    width: 100%;
    position: relative;
    color: #fff;
    border-radius: .25em;
    text-decoration: none;
    transition: all .2s cubic-bezier(var(--transition-main), 1);
}
.navbar-item-inner:hover {
    color: hsl(var(--white));
    background: hsl(var(--background-secondary-light));
    box-shadow: 0 17px 30px -10px hsla(var(--black), .25);
}
.navbar-item-inner-icon-wrapper {
    width: calc(5rem - 1em - 8px);
    position: relative;
}
.navbar-item-inner-icon-wrapper ion-icon {
    position: absolute;
    font-size: calc(var(--navbar-buttons) - 1rem);
}
.link-text {
    margin: 0;
    width: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    transition: all .35s cubic-bezier(var(--transition-main), 1);
    overflow: hidden;
    opacity: 0;
}
#navbar:hover .link-text {
    width: calc(100% - calc(5rem - 8px));
    opacity: 1;
}


/* Responsive design */
@media (max-width: 768px) {
    #navbar:hover {
        width: 4em;
    }
    
    .Tikfloaters{
        font-size: 20px;
        bottom: 10%;
        right: 25%;
        width: 40px;
        height: 40px;
    }
    .Ifloaters {
        font-size: 20px;
        bottom: 10%;
        right: 35%;
        width: 40px;
        height: 40px;
    }
}
</style>