export default {
    MainLabels:{
        title: {
            en:"Let's Talk about it!",
            es:'¡Cuentanos un poco!'
        },
        subtitle: {
            en:"Please fill the required information, so we can be better prepared",
            es:'Por favor llena la información requerida, para que estemos mejor preparados',
        },
        plaintiff: {
            en:'Personal Information',
            es:'Informacion Personal'
        },
        defendant: {
            en: 'Counterpart (if applies)',
            es: 'Contraparte (si aplica)'
        },
        issue: {
            en: 'Issue*',
            es: 'Asunto*'
        }
    },

    plaintiff: {
        fullName: {
            en: 'Full Name*',
            es: 'Nombre Completo*'
        },
        idPassport: {
            en: 'ID / Passport*',
            es: 'ID / Pasaporte*'
        },
        address: {
            en: 'Address*',
            es: 'Dirección*'
        },
        profession: {
            en: 'Profession or occupation*',
            es: 'Profesión u oficio*'
        },
        phoneNumber: {
            en: 'Phone Number*',
            es: 'Número de Teléfono*'
        },
        email: {
            en: 'Email',
            es: 'Correo Electrónico'
        }
    },
    defendant: {
        fullName: {
            en: 'Full Name',
            es: 'Nombre Completo'
        },
        relationship: {
            en: [
                'Boss',
                'Spouse',
                'Friend',
                'Family',
                'Other'
            ],
            es: [
                'Jefe',
                'Conyuge',
                'Amigo',
                'Familiar',
                'Otro'
            ],
        }
    },
    issue: {
        en:[
            'Labor', 
            'Property', 
            'Family Issue',
            'Corporate', 
            'Criminal', 
            'Personal Documents',
            'Others'
        ],
        es:[
            'Trabajo',
            'Propiedad',
            'Asunto Familiar',
            'Corporativo',
            'Criminal',
            'Documentos Personales',
            'Otros' 
        ]
    },
    message: {
        en: 'Write your message',
        es: 'Escribe tu mensaje'
    },
    submitButton: {
        en: 'Submit',
        es: 'Enviar'
    },
    infoMessage:{
        title: {
            en:"I'm here for you!",
            es:'¡Estamos para servirte!'
        },
        parragraph: {
            en:"Feel free to reach out and contact us with any questions.",
            es:'Sientete libre de contactarnos y hacer tus preguntas.',
        }
    },
    infoPhone:{
        title: {
            en:"Phone",
            es:'Telefono'
        },
        parragraph: {
            en:"+504 9645-0625",
            es:'+504 9645-0625',
        }
    },
    infoEmail:{
        title: {
            en:"Email",
            es:'Correo Electronico'
        },
        parragraph: {
            en:"cherrysorto@gmail.com",
            es:'cherrysorto@gmail.com',
        }
    },
    infoHours:{
        title: {
            en:"Schedule",
            es:'Horario'
        },
        parragraph: {
            en:"Monday to Friday",
            es:'Lunes a Viernes',
        },
        hours:{
            en:"10:00AM - 4:00PM",
            es:'10:00AM - 4:00PM',
        }
    }
};
