export default [
    {
        title: { 
            en: 'About Me', 
            es: 'Acerca de Mi' 
        },
        description: { 
            en: "Cherry Sorto, of Honduran nationality, born and raised in Roatan. I graduated in Law from the Faculty of Law at the Technological University of Honduras, UTH. My experience includes the fields of labor law, administrative law, and commercial law. With over 3 years of experience working in human resources, company administrative processes, and the commercial sector, I haven't forgotten my passion and love for animals. I've been working with and supporting organizations to defend and protect animal rights. I uphold high standards of professional development, focusing on continuous improvement, utilizing my skills, and being the voice for those without one. Whatever the problem, I will be part of the solution!",
            es: "Cherry Sorto, de nacionalidad hondureña, nacida y criada en Roatán. Soy licenciada en Derecho por la Facultad de Derecho de la Universidad Tecnológica de Honduras, UTH. Mi experiencia abarca las ramas del derecho laboral, administrativo y comercial. Con más de 3 años de experiencia trabajando en recursos humanos, procesos administrativos de empresas y en el ámbito comercial, sin dejar atrás mi pasión y amor por los animales. He estado trabajando y apoyando organizaciones para defender y proteger los derechos de los animales. Mantengo altos estándares de superación profesional, enfocada en la mejora continua, utilizando mis habilidades y siendo la voz de aquellos que no la tienen. Sea cual sea el problema, ¡seré parte de la solución!"
        },
    }
]