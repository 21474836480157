export default [
    {
        header: { 
            en: 'Welcome to', 
            es: 'Bienvenido a' 
        },
        title: { 
            en: 'Sorto Legal', 
            es: 'Sorto Legal' 
        },
        description: { 
            en: 'Providing legal services in Roatan, Honduras', 
            es: 'Proveyendo servicios legales en Roatan, Honduras.' 
        },
        CTA: { 
            en: 'Contact', 
            es: 'Contacto' 
        },
    }
]