export default {
    MainLabels:{
        title: {
            en:"Schedule a FREE Consultation!",
            es:'Agenda una llamada de consulta, ¡Gratis!'
        },
        description: {
            en:"Pick a day and a time to have a 30-minute virtual consultation, in which we can answer the questions that you need.",
            es:"Elige la hora y fecha para tener tu consulta virtual de 30 minutos donde podrás hacer las preguntas que necesites.",
        },
        name: {
            en:'Full Name*',
            es:'Nombre Completo*'
        },
        email: {
            en: 'Email',
            es: 'Correo'
        },
        phone: {
            en: 'Phone*',
            es: 'Telefono*'
        },
        issue: {
            en: 'Issue*',
            es: 'Asunto*'
        },
        date: {
            en: 'Date',
            es: 'Fecha'
        },
        time: {
            en: 'Time',
            es: 'Hora'
        }
    },
    submitButton: {
        en: 'Submit',
        es: 'Enviar'
    }
};
