<template>
  <Navbar :currentLanguage="currentLanguage" @changeLanguage="updateLanguage" />
  <div id="app" @wheel="onWheel">
    <section><Hero :currentLanguage="currentLanguage" /></section>
    <section><About :currentLanguage="currentLanguage" /></section>
    <section><Meet :currentLanguage="currentLanguage" /></section>
    <section><Services :currentLanguage="currentLanguage" /></section>
    <!--section><FAQs :currentLanguage="currentLanguage" /></section-->
    <!--section><Testimonials :currentLanguage="currentLanguage" /></section-->
    <section style="height: fit-content;"><Contact :currentLanguage="currentLanguage" /></section>
  </div>
</template>

<script>
import Navbar from './components/NavBar.vue'
import Hero from './components/HeroSection.vue'
import About from './components/AboutSection.vue'
import Services from './components/ServicesSection.vue'
//import FAQs from './components/FAQsSection.vue'
import Meet from './components/MeetSection.vue'
//import Testimonials from './components/TestimonialsSection.vue'
import Contact from './components/ContactSection.vue'

export default {
  name: 'App',
  components: {
    Navbar,
    Hero,
    About,
    Services,
    //FAQs,
    Meet,
    //Testimonials,
    Contact
  },
  data() {
    return {
      currentLanguage: 'en' // Set default language here
    };
  },
  methods: {
    updateLanguage(newLanguage) {
      this.currentLanguage = newLanguage;
    }
  }
}
</script>

<style>

:root {
    --primary: 237, 94%, 81%;
    --background: 266, 16%, 92%;
    --background-secondary: 256, 12%, 12%;
    --background-secondary-dark: 256, 10%, 10%;
    --background-secondary-light: 257, 11%, 16%;
    --text-primary: 0, 0%, 0%;
    /* Colors */
    --black: 0, 0%, 0%;
    --white: 0, 0%, 100%;
    --quite-gray: 0, 0%, 50%;
    --grooble: 10, 28%, 93%;
    /* Sizes */
    --heading-large: 5.6rem;
    --heading-medium: 3.6rem;
    --heading-small: 2.4rem;
    --paragraph: 1.11rem;
    --navbar-buttons: 2.4rem;
    /* misc */
    --transition-main: .175, .685, .32;
    /* Fonts */
    --font-main: "Poppins";
}

body{
    width: 100%;
    height: 100%;
    padding: 0%;
    margin: 0%;
    background-color: #002741;
}

#app {
  color: hsl(var(--text-primary));
  font-family: 'Poppins', sans-serif;
  background-color: hsl(var(--background));
  -webkit-font-smoothing: antialiased;
  scroll-behavior: smooth;
  box-sizing:border-box;

  scroll-snap-type: y proximity;
    height:100%;
    margin: 0;
    padding: 0;
}

section {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2em;
    color: white;
    overflow: hidden;
  }

/*section{
    width: 100%;
    min-height: 100vh;
    padding: 0%;
    margin: 0%;
    scroll-snap-align: start;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}*/

/* =============
    ::Selectors
   ============= *

/* Selection */
::selection {
    color: hsl(var(--white));
    background: hsla(var(--primary), .33);
}
/* Scrollbar */
::-webkit-scrollbar-track {
    background-color: hsl(var(--background));
}
::-webkit-scrollbar {
    width: 8px;
    background-color: hsl(var(--background));
}
::-webkit-scrollbar-thumb {
    background-color: hsl(var(--primary));
}

/* ============
    Typography
   ============ */

/* Headings */
h1, h2, h3, h4, h5, h6 {
    margin: 0;
}
/* Font Size */
h1 {
    font-size: var(--heading-large);
}
h2 {
    font-size: var(--heading-medium);
}
h3 {
    font-size: var(--heading-small);
}
h4 {
    font-size: calc(var(--heading-small) - .2rem);
}
h5 {
    font-size: calc(var(--heading-small) - .4rem);
}
h6 {
    font-size: calc(var(--heading-small) - .6rem);
}
/* Font Weight */
h1, h2 {
    font-weight: 900;
}
h3, h4, h5, h6 {
    font-weight: 800;
}
/* Paragraphs */
p {
    margin: 0;
    font-size: var(--paragraph);
}
/* Links */
a {
    color: hsla(var(--primary), 1);
    font-size: var(--paragraph);
    text-decoration: underline;
}
a:visited {
    color: hsla(var(--primary), .5);
}

/* =========
    Buttons
   ========= */

button {
    padding: .8em 1.2em;
    border: 1px solid hsl(var(--black));
    background-color: hsl(var(--background));
    font-size: var(--paragraph);
    cursor: pointer;
    outline: none;
}
button:focus {
    box-shadow:
            0 0 0 2px hsl(var(--black)),
            0 0 0 3px hsl(var(--white));
    border: 1px solid transparent;
}

/* =======
    Lists
   ======= */

ul, ol {
    margin: 1em 0;
}

/* =========
    Flexbox
   ========= */

.flexbox {
    display: flex;
    justify-content: center;
    align-items: center;
}
.flexbox-left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.flexbox-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
/* Columns */
.flexbox-col {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.flexbox-col-left {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
}
.flexbox-col-left-ns {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
}
.flexbox-col-right {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: flex-end;
}
.flexbox-col-start-center {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
}
/* Spacings */
.flexbox-space-bet {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* =========
    Classes
   ========= */

.view-width {
    width: 70%;
}
</style>
