<template>
    <div id="about">
        <div class="parallax-bg"></div>
        <div class="about-content">
            <h2>{{ AboutArray[0].title[currentLanguage] }}</h2>
            <p>{{ AboutArray[0].description[currentLanguage] }}</p>
        </div>
    </div>
</template>

<script>
import AboutFile from '../assets/textfiles/about.js';

export default {
    props: ['currentLanguage'],
    data() {
        return {
            AboutArray: AboutFile
        };
    },
}
</script>

<style>
#about {
    position: relative;
    min-height: 100%;
    display: flex;
    align-items: center;
}

.parallax-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url("../assets/images/blueBG.jpg");
    background-size: cover;
    background-position: center;
    z-index: 0;
    background-attachment: fixed;
}

.about-content {
    position: relative;
    z-index: 1;
    padding: 3em;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    align-content: center;
    text-align: center;
    justify-content: center;
    background-color: #00000048;
    backdrop-filter: blur(10px);
    margin-right: 5%;
    margin-left: 10%;
    border-radius: 25px;
    box-shadow: 0px 0px 20px rgb(0, 0, 0);
}

.about-content h2 {
    font-size: 2.5rem;
    margin-bottom: 1em;
}

.base-content p {
    font-size: 1.2rem;
    line-height: 1.6;
}

/* Responsive design */
@media (max-width: 768px) {
    
    .about-content{
        font-size: 0.5rem;
        margin-left: 20%;
    }
    
    .about-content h2 {
        font-size: 2rem;
        margin-bottom: 0.5em;
    }
 
    .about-content p {
        font-size: 1rem;
        line-height: 1.6;
    }
}
</style>